<template>
  <div class="sidebar">
    <template v-if="addRoutes.length">
      <div v-for="item in updateSidebarList" :key="item.meta.id" class="sidebar-item">
        <div class="title">{{ item.meta.title }}</div>
        <grid :gutter="10" :column-num="3">
          <grid-item v-for="sidebarItem in item.children" :key="sidebarItem.meta.id" :text="sidebarItem.meta.title"
                     :to="updateGridRouterTo(item.path, sidebarItem.path)"></grid-item>
        </grid>
      </div>
    </template>
    <MobileTabbar/>
  </div>
</template>

<script>
import MobileTabbar from '@/components/mobile/MobileTabbar'
import { Grid, GridItem } from 'vant'
import { mapState } from 'vuex'

export default {
  name: 'Sidebar',
  components: {
    MobileTabbar,
    Grid,
    GridItem
  },
  computed: {
    ...mapState('permission', ['addRoutes']),
    updateSidebarList () {
      return this.addRoutes.filter(item => {
        if (item.path !== '*') {
          return item
        }
      })
    }
  },
  methods: {
    // 拼接跳转路径（父，子）
    updateGridRouterTo (p, s) {
      if (p === '/') {
        return `${p}${s}`
      } else {
        return `${p}/${s}`
      }
    }
  }
}
</script>

<style scoped lang="scss">
.sidebar {
  .sidebar-item {
    margin-top: 20px;
    .title {
      font-size: 16px;
      font-weight: bold;
      padding-left: 10px;
    }
  }

}
</style>
